<template>
  <div class="row">
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Adı</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.name }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Ürün Adı</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.productName }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Ürün Fiyatı</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.productPrice }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Süre</div>
      <div class="font-size-lg font-weight-bold">
        {{
          selectedItem.startTime
            ? $customFunctions.setMomentWithFormat(
                selectedItem.startTime,
                'DD.MM.yyyy'
              )
            : '-'
        }}
        /
        {{
          selectedItem.endTime
            ? $customFunctions.setMomentWithFormat(
                selectedItem.endTime,
                'DD.MM.yyyy'
              )
            : '-'
        }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">İndirim (Min - Max)</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.minDiscountRate }} - {{ selectedItem.maxDiscountRate }}
        {{ selectedItem.discountType == 'Ratio' ? '%' : 'TL' }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Sabit İndirim</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.staticDiscountRate }} TL
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Ürün Fiyatı</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.productPrice }} TL
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Durum</div>
      <div class="font-size-lg font-weight-bold">
        <div
          v-html="$customFunctions.getIsActiveBadge(selectedItem.isActive)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailView',
  props: {
    selectedItem: String | Object,
  },
};
</script>
